import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, TextField, Typography } from '@mui/material'
import React from 'react'
import OrderItem from './OrderItem'
import Slide from '@mui/material/Slide';
import { useMutation } from '@tanstack/react-query';
import { request } from '../../api/request';
import { Circles } from 'react-loader-spinner';
import { Formik } from 'formik';
import * as Yup from 'yup'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});




const OrderCard = ({order}) => {
    const [open, setOpen] = React.useState(false);
    const [alterOpen, setAlterOpen] = React.useState(false);
    const [message , setMessage] = React.useState('')
    const [messageType , setMessageType] = React.useState('error')

    console.log(order)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handelAlterClose = () => {
        setAlterOpen()
    }

    const handleClose = () => {
        setOpen(false);
    };
    const toRunnerHandler = () => {
        handleClickOpen()
    }
    const moveToRunnerRequest = (values) => {
      return request({
          url : `/order-to-casher/${order.id}`,
          method : 'patch',
          data : values
      })
  }

    const toRunnerMutation = useMutation({
        mutationKey : [`move-order-${order.id}-to-runner`],
        mutationFn : moveToRunnerRequest,
        onSuccess : (data) => {
            setMessage('Order Move On To Runner Successfully')
            setMessageType('success')
            setAlterOpen(true)
            setOpen(false)
        },
          onError : (error) => {
            if (error.response){
              switch(error.response.status){
                case 401 : {
                    setMessage('you are not authorize to do this operation')
                  setMessageType('error')
                  setAlterOpen(true)
                  break
                }
                case 422 : {
                    setMessage('wrnog data given to server')
                  setMessageType('error')
                  setAlterOpen(true)
                  break
                }
                case 500 : {
                    setMessage('we have a problem in our server , come later')
                  setMessageType('error')
                  setAlterOpen(true)
                  break
                }
                case 404 : {
                    setMessage("we out of space , we can't find your destenation")
                  setMessageType('error')
                  setAlterOpen(true)
                  break
                }
                default : {
                    setMessage("unkown error accoure : request falid with status code" + error.response.status)
                  setMessageType('error')
                  setAlterOpen(true)
                  break
                }
              }
            }else if(error.request){
                setMessage('server response with nothing , Check your internet connection or contact support if the problem persists')
              setMessageType('error')
              setAlterOpen(true)
            }else {
                setMessage('unknow error : ' + error.message)
              setMessageType('error')
              setAlterOpen(true)
            }
          }
    })



    const handelConfirm = (values) => {
        toRunnerMutation.mutate(values)
    }


    if(toRunnerMutation.isLoading){
      return <Box
      sx={{
          display : 'flex',
          alignItems : 'center',
          justifyContent : 'center',
          position : 'absolute',
          backgroundColor : 'white',
          left : '0',
          top : '0',
          width : '100%',
          height : '100%',
          minHeight : '100vh',
          zIndex : '10'
      }}
  ><Circles
  height="80"
  width="80"
  color="#b91c1c"
  ariaLabel="circles-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
/>
</Box>
    }

  return (
    <>
    <Box
        sx={{
            boxShadow : '0 0px 10px 0px #b91c1c',
            borderRadius : '8px'
        }}
    >
        <Box
              sx={{
                  display : 'flex',
                  alignItems : 'center',
                  justifyContent : 'space-between',
                  // padding : '8px',
                  borderRadius : '8px 8px 0px 0px',
                  position : 'relative',
                  overflow : 'hidden'
              }}
        >
            <Box
                sx={{
                    width : '100%',
                    height : '100%',
                    position : 'absolute',
                    backgroundColor : '#F3F3F3',
                    // filter : 'blur(10px)',
                    // zIndex : '-1'
                }}
            >

            </Box>
            <Box
             sx={{
                    padding:'10px',
                    zIndex:'1'
                }}
            >
                <Typography>
                    {`Order ID: ${order.id}`}
                </Typography>
                <Typography>
                    {`Table Number: ${order.table_id}`}
                </Typography>
                <Typography
                    sx={{
                        marginBottom : '10px'
                    }}
                    >
                    {`Total : ${order.total} SAR`}
                </Typography>
            </Box>
            <Box>
                
                <Button
                    color='success'
                    variant='outlined'
                    onClick={toRunnerHandler}
                    sx={{
                      margin:'10px'
                    }}
                >
                    done
                </Button>
            </Box>
        </Box>
        <Box>
            {
                order.order_items.map(orderItem => (
                    <OrderItem key={orderItem.id} orderItem={orderItem} />
                    ))
                }
        </Box>
    </Box>
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirmation Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are You Sure You Want To Deliver This Order ?
          </DialogContentText>
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handelConfirm}
            >
              {
                ({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="receipt_id"
                      value={values.receipt_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.receipt_id && !!touched.receipt_id}
                      helperText={errors.receipt_id && touched.receipt_id}
                      label="Receipt ID"
                      name="receipt_id"
                      autoFocus
                      color='primary'
                    /> */}
                    <Box
                    sx={{display:'flex',
                    justifyContent:'space-between',
                    gap:'5px'
                  }}
                    >
                   
                   
          <Button
          fullWidth
          color='error'
          variant='outlined'
          // variant="contained"
          sx={{ mt: 3, mb: 2 , padding : '10px'}}
          onClick={handleClose}>Cancel</Button>
           <Button
                      type="submit"
                      fullWidth
                      color='success'
                      variant='outlined'
                      // variant="contained"
                      sx={{ mt: 3, mb: 2 ,padding : '10px'}}
                    >
                      Confirm
                    </Button>
                    </Box>
                  </form>
                )
              }
              
            </Formik>
        </DialogContent>
        
      </Dialog>
      <Snackbar open={alterOpen} autoHideDuration={4000} onClose={handelAlterClose}>
        <Alert onClose={handelAlterClose} severity={messageType} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}


const initialValues = {
  receipt_id : '1',
}
const validationSchema = Yup.object({
  receipt_id : Yup.string().required('email field is required'),
})

export default OrderCard